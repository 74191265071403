import "./assets/css/style.css";
import { Header } from "./components/Header";
import { Main } from "./components/Main";
import mask from "./assets/img/Mask group.png";
import { Story } from "./components/Story";
import { Token } from "./components/Token";
import { Tokenomics } from "./components/Tokenomics";
import { RoadMap } from "./components/RoadMap";
import { Meet } from "./components/Meet";
import { Footer } from "./components/Footer";
import rightfrog from "./assets/img/right-frog.png";
function App() {
  return (
    <div className="main">
      <img src={rightfrog} class="right-frog" alt="" />
      <Header />
      <img className="rays" src={mask} alt="shine img" />
      <Main />
      <Story />
      <Token />
      <Tokenomics />
      <RoadMap />
      <Meet />
      <div className="line"></div>
      <Footer />
    </div>
  );
}

export default App;
