import React from "react";
import logo from "../assets/img/logo.svg";
import twitter from "../assets/img/twitter.svg";
import telegram from "../assets/img/telegram.svg";
import thirdicon from "../assets/img/third-icon.svg";
import fourthicon from "../assets/img/fourth-icon.svg";
import fifthicon from "../assets/img/fifth-icon.svg";
import sixthicon from "../assets/img/sixth-icon.svg";
export const Meet = () => {
  return (
    <div className="six_wrapper common_width">
      <div className="six_top">
        <div className="six_top_header">
          <img src={logo} alt="svg" />
          <p>HOPPY</p>
        </div>
        <p>
          Meet $HOPPY the frog. The first frog Matt Furie created in 2004.
          Everyone knows Pepe, the viral internet meme seen billions of times...
          but did you know who Pepe was based off?
        </p>
      </div>
      <div className="six_bottom">
        <div>
          <a href="https://x.com/HoppySol" target="_blank">
            <img src={twitter} alt="icon" />
          </a>
        </div>

        <div>
          <a href="https://t.me/HoppySol" target="_blank">
            <img src={telegram} alt="icon" />
          </a>
        </div>

        <div>
          <a href="#">
            <img src={thirdicon} alt="icon" />
          </a>
        </div>

        <div>
          <a href="#">
            <img src={fourthicon} alt="icon" />
          </a>
        </div>

        <div>
          <a href="#">
            <img src={fifthicon} alt="icon" />
          </a>
        </div>

        <div>
          <a href="#">
            <img src={sixthicon} alt="icon" />
          </a>
        </div>
      </div>
    </div>
  );
};
