import React from "react";
import logo from "../assets/img/logo.svg";
export const Header = () => {
  return (
    <header className="header common_width">
      <div className="logo">
        <img src={logo} alt="logo" />
        <h1>HOPPY</h1>
      </div>
      <nav>
        <a href="#About">About</a>
        <a href="#Tokenomics">Tokenomics</a>
        <a href="https://t.me/HoppySol" target="_blank">
          Join Us
        </a>
      </nav>
      <button className="btn">Buy on Raydium</button>
    </header>
  );
};
