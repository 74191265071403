import React from "react";
import Groupcircle from "../assets/img/Group circle.svg";
export const RoadMap = () => {
  return (
    <div className="fifth_wrapper common_width">
      <h3>Road map</h3>
      <div className="fifth_content">
        <div className="fifth_card">
          <div className="fifth_card_content">
            <span>Ongoing</span>
            <p>PHASE 1</p>
            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Launch</p>
            </div>

            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Dexscreen and Dextools update</p>
            </div>

            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Sol Trending</p>
            </div>

            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Coingecko/Coinmarketcap Listings</p>
            </div>

            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Community Grow</p>
            </div>

            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Huge Marketing Push</p>
            </div>
          </div>
        </div>

        <div className="fifth_card">
          <div className="fifth_card_content">
            <span>Ongoing</span>
            <p>PHASE 2</p>
            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Listing on Major Exchanges</p>
            </div>

            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Partnerships</p>
            </div>
          </div>
        </div>

        <div className="fifth_card">
          <div className="fifth_card_content">
            <span>Ongoing</span>
            <p>PHASE 3</p>
            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>STAKING</p>
            </div>

            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Meme Takeover</p>
            </div>

            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>10,000+ holders</p>
            </div>

            <div className="phase_points">
              <img src={Groupcircle} alt="svg" />
              <p>Moon</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
