import React from "react";
import Group from "../assets/img/Group.png";
export const Main = () => {
  return (
    <div className="first_wrapper common_width">
      <div className="first_left_wrapper">
        <div className="first_text_wrapper">
          <p>Welcome to HOPPY ON SOL!!!</p>
          <h4>HOPPY ON SOL</h4>
          <p>
            Meet $HOPPY the frog. The first frog Matt Furie created in 2004.
            Everyone knows Pepe, the viral internet meme seen billions of
            times..
          </p>
        </div>
        <button className="first_left_btn">Buy on Raydium</button>
      </div>
      <div className="first_right_wrapper">
        <img src={Group} alt="img" />
      </div>
    </div>
  );
};
