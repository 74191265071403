import React from "react";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_content common_width">
        <p>Copyright © 2024 Hoppy. All rights reserved.</p>
        <p>Privacy Policy</p>
      </div>
    </footer>
  );
};
