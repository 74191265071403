import React from "react";
import videoarea from "../assets/video/video.mp4";
export const Story = () => {
  return (
    <div className="second_wrapper common_width" id="About">
      <h3>The Story of Hoppy</h3>
      <p>
        Meet $HOPPY the frog. The first frog Matt Furie created in 2004.
        Everyone knows Pepe, the viral internet meme seen billions of times...
        but did you know who Pepe was based off? Thats right, Hoppy! Pepe was
        highly relatable to viewers across the world, but few realized that
        Hoppy had been the frog that inspired it all. It was Hoppy's spirit that
        had first awakened Matt's imagination, igniting the flame that would
        eventually bring Pepe into the limelight. While Hoppy may not have
        achieved the same level of widespread recognition as Pepe, he held a
        special place in the heart of the artist and those who had the privilege
        of discovering him.
      </p>
      <video
        className="common_width"
        src={videoarea}
        controls
        muted
        autoPlay
      ></video>
    </div>
  );
};
