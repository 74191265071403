import React from "react";
import firstRight from "../assets/img/firstRight.svg";
import image17 from "../assets/img/image17.svg";
export const Token = () => {
  return (
    <div className="third_wrapper common_width">
      <div className="third_heading">
        <h2>token SUPPLY</h2>
        <p>Ca: coming soon</p>
      </div>
      <div className="third_token_supply">
        <p>
          Total Supply: <span>100000000</span>
        </p>
        <p>
          Tokens For Liquidity: <span>90000000</span>
        </p>
        <p>
          starting LP: <span>TBA</span>
        </p>
      </div>
      <div className="third_card_wrapper">
        <div className="third_card">
          <div className="third_card_content">
            <p>
              Ticker: <span>$HOPPY</span>
            </p>
            <div className="third_content_points">
              <div className="circle"></div>
              <p>Total Supply: 100M</p>
            </div>
            <div className="third_content_points">
              <div className="circle"></div>
              <p>Date Launched: TBA</p>
            </div>
            <div className="third_content_points">
              <div className="circle"></div>
              <p>Team Tokens: 0</p>
            </div>
          </div>
        </div>

        <div className="third_card">
          <div className="third_card_content">
            <p>LISTING</p>
            <div className="third_content_points">
              <div className="circle"></div>
              <p>LISTING ON :</p>
            </div>
            <div className="third_small_points">
              <div className="small_circle"></div>
              <p>Eye bird</p>
            </div>
            <div className="third_small_points">
              <div className="small_circle"></div>
              <p>Rydm</p>
            </div>
            <div className="third_small_points">
              <div className="small_circle"></div>
              <p>Dex screen</p>
            </div>
          </div>
        </div>

        <div className="third_card">
          <div className="third_card_content">
            <p>TAX And LP</p>
            <div className="third_content_points">
              <div className="circle"></div>
              <p>TAX : 0/0</p>
            </div>
            <div className="third_content_points">
              <div className="circle"></div>
              <p>LP: BURN</p>
            </div>
          </div>
        </div>
      </div>

      <img className="right_small_img" src={firstRight} alt="right small img" />
      <img className="left_small_img" src={image17} alt="left small img" />
    </div>
  );
};
