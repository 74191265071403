import React from "react";
import Ellipse111 from "../assets/img/Ellipse 111.svg";
import Ellipse112 from "../assets/img/Ellipse 112.svg";
import Chart from "react-apexcharts";
export const Tokenomics = () => {
  return (
    <div className="fourth_wrapper common_width" id="Tokenomics">
      <div className="fourth_left">
        <h3>Tokenomics</h3>
        <p>
          Meet $HOPPY the frog. The first frog Matt Furie created in 2004.
          Everyone knows Pepe, the viral internet meme seen billions of times...
          but did you know who Pepe was based off?
        </p>
        <div className="forth_left_bottom">
          <div className="forth_bottom">
            <img src={Ellipse111} alt="svg" />
            <p>90% Lp add</p>
          </div>
          <div className="forth_bottom">
            <img src={Ellipse112} alt="svg" />
            <p>10% marketing</p>
          </div>
        </div>
      </div>
      <div className="fourth_right">
        <Chart
          options={{
            labels: ["Half Supply:", "Half Supply"],
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: false,
            },
            colors: ["#81DEFD", "#D8F43B"],
            plotOptions: {
              pie: {
                // customScale: 1.2,
                donut: {
                  size: "80%",
                  labels: {
                    show: true,

                    value: {
                      show: true,
                      fontSize: "36px",

                      fontWeight: 400,
                      color: "#fff",
                      label: "80%",
                      offsetY: 16,
                      cssClass: "db-sans",
                      formatter: function (val) {
                        return val;
                      },
                    },
                    total: {
                      show: true,
                      showAlways: false,
                      label: "Total Supply:",
                      fontSize: "22px",
                      fontWeight: 400,
                      color: "#7F8083",
                      cssClass: "font-montserrat",
                      formatter: function (w) {
                        return w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0);
                      },
                    },
                  },
                },
              },
            },
          }}
          series={[90000000, 10000000]}
          type="donut"
          width={390}
        />
      </div>
    </div>
  );
};
